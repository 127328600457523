import { showLoading } from '@store/actions';
import { getUserToken } from '@utils';
import { BASE_LOGIN } from '@src/constants/initHttp';
import { ADD_FILE_GDRIVE, READ_FILE_GDRIVE } from '@type';
import axios from 'axios';
import { reportSentry } from '../../../utils/Utils';

export const readFileGDrive = (data) => (dispatch) => {
  dispatch(showLoading(true));
  return axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/${data.id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((res) => {
      dispatch({ type: READ_FILE_GDRIVE, payload: res.data });
      dispatch(showLoading(false));
      return res.data.data;
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};
export const nativeReadFileGDrive = (data) =>
  axios
    .get(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/${data.id}`, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((res) => res.data.data)
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      reportSentry(error);
    });

export const setPermissionFileGDrive = (data) => (dispatch) => {
  dispatch(showLoading(true));
  return axios
    .put(
      `${BASE_LOGIN}/v1/api/schoolGoogleDrive/permission/id/${data.id}`,
      [
        {
          type: 'anyone',
          role: 'reader',
          send_notification: false,
        },
      ],
      {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      }
    )
    .then(async () => {
      dispatch(showLoading(false));
      return await dispatch(readFileGDrive(data));
    })
    .catch((error) => {
      // notification.error({
      //   message: 'Mohon Maaf!',
      //   description: 'Silahkan Hubungi Customer Service Kami',
      // });
      dispatch(showLoading(false));
      reportSentry(error);
    });
};
export const nativeSetPermissionFileGDrive = (data) =>
  Promise.all([
    axios.put(
      `${BASE_LOGIN}/v1/api/schoolGoogleDrive/permission/id/${data.id}`,
      [
        {
          type: 'anyone',
          role: 'reader',
          send_notification: false,
        },
      ],
      {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      }
    ),
    nativeReadFileGDrive(data),
  ]);

export const uploadGDrive = (data, withError = true, withCompress = false) => {
  const api = (dispatch) => {
    dispatch(showLoading(true));
    return axios
      .post(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/file/user?isCompress=${withCompress ? 'true' : 'false'}`, data, {
        headers: { Authorization: `Bearer ${getUserToken()}` },
      })
      .then((res) => {
        dispatch({ type: ADD_FILE_GDRIVE, payload: res.data });
        dispatch(showLoading(false));
        return dispatch(setPermissionFileGDrive(res.data.data));
      })
      .catch((error) => {
        if (withError) {
          // notification.error({
          //   message: 'Mohon Maaf!',
          //   description: 'Silahkan Hubungi Customer Service Kami',
          // });
        }
        dispatch(showLoading(false));
        reportSentry(error);
        throw error;
      });
  };
  return api;
};

export const nativeUploadGDrive = (data) =>
  axios
    .post(`${BASE_LOGIN}/v1/api/schoolGoogleDrive/file/user?isCompress=true`, data, {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });

export const uploadGDriveDirect = (file) => async (dispatch) => {
  const acc = localStorage.getItem('acc');
  dispatch(showLoading(true));
  const api = await axios({
    url: 'https://www.googleapis.com/upload/drive/v3/files',
    params: { uploadType: 'multipart', fields: '*', access_token: acc },
    headers: { 'content-type': 'multipart/form-data' },
    data: file,
    method: 'post',
  })
    .then((response) => {
      dispatch(showLoading(false));
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      dispatch(showLoading(false));
      reportSentry(error);
    });

  return api;
};
